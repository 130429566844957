// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_NZqPO{display:flex;flex-direction:column;gap:1rem}.grid_E3ERW{display:grid;grid-template-columns:1fr 1fr;grid-gap:2rem 1rem;gap:2rem 1rem}.row_1tcYM{align-items:center;display:flex;gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_NZqPO",
	"grid": "grid_E3ERW",
	"row": "row_1tcYM"
};
module.exports = ___CSS_LOADER_EXPORT___;
