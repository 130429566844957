// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_TMwcs{display:flex;flex-direction:column}.flex_1W5Zq{flex:1}.modalContainer_Ec08E{padding:1rem}.scroll_M4Utk{border-bottom:1px solid var(--color-black-50);max-height:30rem;overflow:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_TMwcs",
	"flex": "flex_1W5Zq",
	"modalContainer": "modalContainer_Ec08E",
	"scroll": "scroll_M4Utk"
};
module.exports = ___CSS_LOADER_EXPORT___;
